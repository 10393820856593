<script setup>
const { locale } = useI18n()
const route = useRoute()
const localeRoute = useLocaleRoute()
const getRouteBaseName = useRouteBaseName()

const activeClass = computed(() => (type) => locale.value === type && 'font-semibold')

const changeLocale = (type) => {
	const name = getRouteBaseName(route)
	const toRoute = localeRoute(name, type)
	const { origin, search, hash } = window.location

	window.location = origin + toRoute.path + search + hash
}
</script>

<template>
	<div class="flex gap-1.5">
		<a
			href="/"
			:class="activeClass('es')"
			@click.prevent="changeLocale('es')"
		>
			ES
		</a>
		|
		<a
			href="/en"
			:class="activeClass('en')"
			@click.prevent="changeLocale('en')"
		>
			EN
		</a>
	</div>
</template>
